import React from "react";
import clsx from "clsx";
import { CheckIcon } from "components/atoms/icons/CheckIcon";
import { Modal } from "components/atoms/Modal";
import { useWispSettings } from "contexts/WispSettingsContext";
import { getCustomRpcs } from "utils/constant";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export function SelectCustomRpc({ isOpen, onClose }: Props): React.ReactElement<Props> {
  const { settings, updateSettings } = useWispSettings();

  const handleSelectCustomRPC = (value: string): void => {
    updateSettings({ customRPC: value });
  };

  const customRpc = getCustomRpcs(settings.networkEnv);

  return (
    <Modal isOpen={isOpen} title="Custom RPC" onClose={onClose}>
      <div className="space-y-6">
        <div>Select your custom RPC.</div>
        <div className="rounded-md border border-white border-opacity-10 max-h-60 overflow-y-auto overflow-x-hidden h-full">
          {customRpc.map((item) => {
            const isSelected = item.rpc === settings.customRPC;
            return (
              <div
                className={clsx(
                  "cursor-pointer py-2 hover:bg-dark-400 flex items-center",
                  isSelected ? "bg-dark-400 px-2" : "px-10",
                )}
                key={item.rpc}
                onClick={(): void => handleSelectCustomRPC(item.rpc)}
              >
                {isSelected && <CheckIcon className="w-6 h-6 mr-2 text-pGreen-500 shrink-0" />}
                <div>
                  <div className="font-medium">{item.name}</div>
                  <div className="text-sm text-gray-500">{item.rpc}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Modal>
  );
}
