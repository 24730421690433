import { ReactElement, useState } from "react";
import clsx from "clsx";
import { AssetLogo } from "components/atoms/AssetLogo";
import { TriangleDownIcon } from "components/atoms/icons/TriangleDownIcon";
import { Amount } from "sdk/entities/amount";
import { Coin } from "sdk/entities/coin";

import { SelectAssetModal } from "./SelectAssetModal";

type Props = {
  label: string;
  value: string | undefined;
  asset: Coin | undefined;
  balance: Amount | undefined;
  otherAsset: Coin | undefined;
  maxAmount?: boolean;
  readonly?: boolean;
  onSelect: (_: Coin) => void;
  onInputChange: (value: string) => void;
  onMaxAmount?: () => void;
  fixedCoin?: boolean;
  balances?: Amount[];
  isFaucet?: boolean;
};

export function AssetInput({
  label,
  value,
  asset,
  balance,
  balances,
  otherAsset,
  maxAmount,
  readonly,
  fixedCoin = false,
  isFaucet = false,
  onSelect,
  onInputChange,
  onMaxAmount,
}: Props): ReactElement {
  const [isOpenSelectAssetModal, setIsOpenSelectAssetModal] = useState<boolean>(false);

  const handleMaxAmount = (): void => {
    if (maxAmount) {
      onMaxAmount?.();
    }
  };

  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>): void => {
    let newValue = event.target.value.replace(/[^\d.]/gi, "");
    if (newValue.startsWith(".")) {
      newValue = "0" + newValue;
    }
    if (newValue.endsWith(".") && newValue.indexOf(".") !== newValue.length - 1) {
      newValue = newValue.substring(0, newValue.length - 1);
    }
    onInputChange(newValue);
  };

  return (
    <div>
      <div className="mb-2 flex justify-between items-center text-xs">
        <div className="text-pNeutral-800 font-semibold uppercase">{label}</div>
        <div className={maxAmount ? "cursor-pointer" : ""} onClick={handleMaxAmount}>
          {balance?.toExact()}
        </div>
      </div>
      <div className="flex justify-between bg-white bg-opacity-10 py-2 pl-4 pr-2 rounded-lg space-x-4">
        <div className="flex-1 font-medium flex items-center text-base min-w-0">
          <input
            className="bg-transparent flex-1 pr-2 font-DmSans min-w-0"
            placeholder="0.0"
            readOnly={readonly}
            type="text"
            value={value}
            onChange={handleChangeInput}
          />
        </div>
        <div
          className={clsx(
            "shrink-0 flex items-center space-x-2 relative rounded-md pl-2",
            "before:w-px before:h-full before:bg-white before:bg-opacity-30 before:-left-2 before:absolute",
            !fixedCoin && "hover:bg-white hover:bg-opacity-5 cursor-pointer",
          )}
          onClick={(): void => {
            !fixedCoin && setIsOpenSelectAssetModal(true);
          }}
        >
          <AssetLogo asset={asset} className="!w-6 !h-6" />
          <span className="text-sm font-medium">{asset ? asset.name : "Select"}</span>
          {!fixedCoin ? <TriangleDownIcon className="w-8 h-8 shrink-0" /> : <div />}
        </div>
      </div>
      {!fixedCoin && balances && (
        <SelectAssetModal
          assets={balances}
          highlightAsset={otherAsset}
          isFaucet={isFaucet}
          isOpen={isOpenSelectAssetModal}
          selectedAsset={asset}
          onClose={(): void => setIsOpenSelectAssetModal(false)}
          onSelect={(a): void => {
            onSelect(a);
            setIsOpenSelectAssetModal(false);
          }}
        />
      )}
    </div>
  );
}
