import { ReactElement } from "react";
import { WalletProvider } from "@suiet/wallet-kit";
import { WispSettingsProvider } from "contexts/WispSettingsContext";
import { domAnimation, LazyMotion } from "framer-motion";
import { WormHole } from "modules/bridge/WormHole";
import { WisppointView } from "modules/wisppoint";
import { QueryClient, QueryClientProvider } from "react-query";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import Layout from "./components/atoms/Layout";
import { WalletHelperContext } from "./contexts/WalletHelperContext";
import { PATH } from "./utils/constant";

import "react-toastify/dist/ReactToastify.min.css";

const queryClient = new QueryClient();

function App(): ReactElement {
  return (
    <QueryClientProvider client={queryClient}>
      <WalletProvider>
        <LazyMotion features={domAnimation} strict>
          <WispSettingsProvider>
            <WalletHelperContext>
              <Layout>
                <Routes>
                  <Route element={<WormHole />} path={PATH.WORMHOLE} />
                  <Route element={<WisppointView />} path="*" />
                </Routes>
              </Layout>
            </WalletHelperContext>
          </WispSettingsProvider>
          <ToastContainer
            autoClose={5_000}
            bodyClassName="text-white [&>div:nth-child(2)]:line-clamp-3"
            hideProgressBar={false}
            newestOnTop={true}
            position="top-right"
            rtl={false}
            toastClassName="md:top-16 bg-dark-600 z-[100]"
            closeOnClick
            pauseOnFocusLoss
            pauseOnHover
          />
        </LazyMotion>
      </WalletProvider>
    </QueryClientProvider>
  );
}

export default App;
