import React, { useMemo, useState } from "react";
import { TransactionBlock } from "@mysten/sui.js";
import { useWallet } from "@suiet/wallet-kit";
import CoinAPI from "api/coin";
import { Button } from "components/atoms/Button";
import { Modal } from "components/atoms/Modal";
import { useWalletHelper } from "contexts/WalletHelperContext";
import { useWispSettings } from "contexts/WispSettingsContext";
import { useAllBalances } from "hooks/useAllBalances";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { Amount } from "sdk/entities/amount";
import { Coin } from "sdk/entities/coin";
import { toCommaSeparated } from "sdk/utils/formatNumber";
import { isTooManyDecimals, tryParseAmount } from "sdk/utils/tryParseAmount";
import { NetworkEnv, WISP_CONFIG } from "utils/constant";

import { AssetInput } from "./AssetInput";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export function RequestFaucetModal({ isOpen, onClose }: Props): React.ReactElement<Props> {
  const [asset, setAsset] = useState<Coin>();
  const [typedValue, setTypedValue] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const { settings } = useWispSettings();
  const suietWallet = useWallet();

  const { signAndExecuteTransaction } = useWalletHelper();
  const { fungibleBalance: fungibleBalances } = useAllBalances(
    settings.customRPC,
    settings.networkEnv,
    suietWallet.address,
  );

  const { data: faucetCoins } = useQuery<Coin[]>(
    ["get-coin-faucet", settings.networkEnv],
    async () => {
      try {
        const resp = await CoinAPI.getCoinFaucet(settings.networkEnv);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (resp.data ?? []).map((piece: any) => {
          return new Coin(piece.package_addr, piece.module, piece.type, piece.decimal ?? 0, piece.treasury_addr, {
            imageUrl: piece.icon_url,
            ticker: piece.symbol,
            projectName: piece.symbol,
            description: piece.description,
          });
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        console.error(err);
        toast.error(err.message ?? "Fetching faucet coins error");
        return [];
      }
    },
    {
      enabled: settings.networkEnv !== NetworkEnv.MAINNET,
    },
  );

  const assetBalances = useMemo(() => {
    return (faucetCoins ?? []).map((c: Coin) => {
      const bl = (fungibleBalances ?? []).find((fb: Amount) => fb.coin.equals(c));
      if (bl) {
        return bl;
      }
      return Amount.fromRawAmount(c, 0);
    });
  }, [fungibleBalances, faucetCoins]);

  const amount: Amount | null = useMemo(() => {
    if (!asset) {
      return null;
    }
    return tryParseAmount(typedValue || "0", asset) ?? null;
  }, [asset, typedValue]);

  async function handleRequest(): Promise<void> {
    if (!suietWallet.address) {
      return;
    }
    try {
      if (!asset || !amount || amount.equalTo(0)) {
        return;
      }
      setLoading(true);
      const tx = new TransactionBlock();
      tx.moveCall({
        target: `${asset.packageObject}::${asset.module}::${WISP_CONFIG.dex.functions.mint}`,
        arguments: [
          tx.object(asset.treasury ?? ""),
          tx.pure(amount.quotient.toString()),
          tx.pure(suietWallet.address || ""),
        ],
      });
      await signAndExecuteTransaction(tx);
      setTypedValue("");
      onClose();
    } catch (e) {
      console.error(e);
      toast.error("Request failed");
    } finally {
      setLoading(false);
    }
  }

  function handleChangeInput(value: string): void {
    if (value.length > 4 || isTooManyDecimals(value, asset)) {
      return;
    }
    setTypedValue(value);
  }

  function handleSelectAsset(a: Coin): void {
    setAsset(a);
    setTypedValue("");
  }

  return (
    <Modal isOpen={isOpen} title="Request Faucet" onClose={onClose}>
      <div className="space-y-6">
        <div>Enter your amount to receive tokens on your wallet.</div>
        <div>
          <AssetInput
            asset={asset}
            balance={undefined}
            balances={assetBalances}
            isFaucet={true}
            label="Amount"
            otherAsset={undefined}
            value={toCommaSeparated(typedValue)}
            onInputChange={handleChangeInput}
            onSelect={handleSelectAsset}
          />
        </div>
        <div>
          <Button
            className="px-6 py-3 w-full text-xl font-semibold font-Poppins whitespace-pre-wrap"
            disabled={!amount || amount.equalTo(0)}
            isLoading={loading}
            onClick={handleRequest}
          >
            {loading ? "Requesting" : "Request"}
          </Button>
        </div>
      </div>
    </Modal>
  );
}
