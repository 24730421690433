import { ReactElement, useState } from "react";
import { Connection, JsonRpcProvider, TransactionBlock } from "@mysten/sui.js";
import { useWallet } from "@suiet/wallet-kit";
import { Button } from "components/atoms/Button";
import { useWalletHelper } from "contexts/WalletHelperContext";
import { useWispSettings } from "contexts/WispSettingsContext";
import moment from "moment";
import { useQuery } from "react-query";
import invariant from "tiny-invariant";
import { shortenAddress } from "utils";
import { WISP_CONFIG } from "utils/constant";

type NftRegistry = {
  startTime: Date;
  endTime: Date;
  sprout_limit: number;
  sprout_minted: number;
  adept_minted: number;
  ancient_minted: number;
  master_minted: number;
  whitelistId: string;
};

type NftData = {
  rank: NFT_RANK;
  mintable: boolean;
};

enum NFT_RANK {
  ANCIENT = 4,
  MASTER = 3,
  ADEPT = 2,
  SPROUT = 1,
  UNRANKED = 0,
}

const RankData = {
  [NFT_RANK.ANCIENT]: {
    name: "Ancient",
    image: "/images/nft-ranking/ancient.png",
    functionName: "mint_ancient",
  },
  [NFT_RANK.MASTER]: {
    name: "Master",
    image: "/images/nft-ranking/master.png",
    functionName: "mint_master",
  },
  [NFT_RANK.ADEPT]: {
    name: "Adept",
    image: "/images/nft-ranking/adept.png",
    functionName: "mint_adept",
  },
  [NFT_RANK.SPROUT]: {
    name: "Sprout",
    image: "/images/nft-ranking/sprout.png",
    functionName: "mint_sprout",
  },
  [NFT_RANK.UNRANKED]: {
    name: "",
    image: "/images/nft-ranking/ancient.png",
    functionName: "",
  },
};

export function NftMinting(): ReactElement {
  const { address } = useWallet();
  const { settings } = useWispSettings();
  const { setOpenSelectWallet, signAndExecuteTransaction } = useWalletHelper();
  const [isBuildingTx, setIsBuildingTx] = useState<boolean>(false);

  const { data: registryData, isFetching: isRegistryFetching } = useQuery<NftRegistry>(
    ["get-nft-registry-data", settings.customRPC],
    async (): Promise<NftRegistry> => {
      const provider = new JsonRpcProvider(
        new Connection({
          fullnode: settings.customRPC,
        }),
      );
      const resp = await provider.getObject({
        id: WISP_CONFIG.nftMinting.registry,
        options: {
          showContent: true,
        },
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const fields = (resp?.data?.content as any)?.fields;
      return {
        startTime: new Date(Number(fields?.start_time)),
        endTime: new Date(Number(fields?.end_time)),
        sprout_minted: Number(fields?.sprout_minted),
        sprout_limit: Number(fields?.sprout_limit),
        adept_minted: Number(fields?.adepts_minted),
        ancient_minted: Number(fields?.ancients_minted),
        master_minted: Number(fields?.masters_minted),
        whitelistId: fields?.whitelist?.fields?.id?.id,
      };
    },
  );

  const now = Date.now();
  const isOverStart = !!registryData && registryData.startTime.getTime() <= now;
  const isOverEnd = !!registryData && registryData.endTime.getTime() < now;
  const isMintActive = isOverStart && !isOverEnd;

  const { data: nftData, isFetching: isNftDataFetching } = useQuery<NftData>(
    ["get-nft-mintable-data", settings.customRPC, address],
    async (): Promise<NftData> => {
      if (!address) {
        return {
          rank: NFT_RANK.UNRANKED,
          mintable: false,
        };
      }
      invariant(!!registryData?.whitelistId, "Invalid whitelist id");
      const provider = new JsonRpcProvider(
        new Connection({
          fullnode: settings.customRPC,
        }),
      );
      const resp = await provider.getDynamicFieldObject({
        parentId: registryData.whitelistId,
        name: {
          type: "address",
          value: address,
        },
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const fields = (resp?.data?.content as any)?.fields?.value?.fields;
      if (!fields) {
        return {
          rank: NFT_RANK.UNRANKED,
          mintable: false,
        };
      }
      return {
        rank: fields.type as NFT_RANK,
        mintable: !fields.minted,
      };
    },
    {
      enabled: !!registryData?.whitelistId,
    },
  );

  const handleMintNft = async (): Promise<void> => {
    invariant(isMintActive, "Not active time");
    invariant(address, "No address found");
    invariant(nftData, "No nftData found");
    const funcName = RankData[nftData.rank].functionName;
    invariant(!!funcName, "No function name");
    setIsBuildingTx(true);
    try {
      const tx = new TransactionBlock();
      tx.moveCall({
        target: `${WISP_CONFIG.nftMinting.package}::${WISP_CONFIG.nftMinting.module}::${funcName}`,
        arguments: [tx.object(WISP_CONFIG.nftMinting.registry), tx.object("0x6")],
      });
      tx.setGasBudget(100_000_000);
      await signAndExecuteTransaction(tx);
    } catch (e) {
      console.error(e);
    } finally {
      setIsBuildingTx(false);
    }
  };

  const isLimitExceeded =
    !!nftData &&
    nftData.rank === NFT_RANK.SPROUT &&
    nftData.mintable &&
    !!registryData &&
    registryData.sprout_minted >= registryData.sprout_limit;

  const rankData = RankData[nftData?.rank as NFT_RANK];

  return (
    <div className="w-full max-w-3xl bg-dark-600 rounded-2xl shadow-2xl p-8 flex flex-col lg:flex-row lg:space-x-6 justify-between items-center">
      <div className="w-[200px] shrink-0">
        <img className="w-full" src={rankData?.image || "/images/nft-ranking/ancient.png"} />
      </div>
      <div className="flex-1">
        <div>
          <div className="mt-2 text-white font-semibold text-center lg:text-left text-2xl">
            {rankData?.name} Wisp NFT
          </div>
          <div className="text-pGreen-500 font-semibold text-center lg:text-left text-2xl mt-2">
            {shortenAddress(address, 7)}
          </div>
        </div>
        <div className="mt-6">
          {!!registryData && !isMintActive && (
            <div className="bg-yellow-500 rounded bg-opacity-20 text-orange-600 p-4 mt-4 text-sm">
              {!isOverStart ? (
                <span>
                  Minting Wisp NFT has not started yet. Minting will start on{" "}
                  <span className="font-semibold">
                    {moment(registryData.startTime).format("MMMM Do, YYYY - h:mm:ss a")}
                  </span>{" "}
                  your local time.
                </span>
              ) : (
                <span>Wisp NFTs minting has ended. Thank you for your interest in Wisp NFTs.</span>
              )}
            </div>
          )}
          {isMintActive && !address && (
            <>
              <div className="border border-white border-opacity-20 border-dashed rounded p-4 text-sm text-pNeutral-800 space-y-2">
                Please connect to your wallet first.
              </div>
              <Button
                className="px-6 py-3 w-full text-xl font-Poppins whitespace-pre-wrap mt-4"
                onClick={(): void => setOpenSelectWallet(true)}
              >
                Connect Wallet
              </Button>
            </>
          )}
          {isMintActive && address && nftData && nftData?.rank === NFT_RANK.UNRANKED && (
            <div className="border border-white border-opacity-20 border-dashed rounded p-4 text-sm text-pNeutral-800 space-y-2">
              <div>
                Sorry, it looks like you're not currently eligible to mint a{" "}
                <span className="text-base font-medium text-pGreen-400">Wisp of the Forest</span> NFT.
              </div>
              <div>To learn more about eligibility requirements, please visit our Discord server.</div>
              <div className="italic">
                Thank you for your interest in the{" "}
                <span className="text-base font-medium text-pGreen-400">Wisp of the Forest</span> NFT Collection. Your
                support means a lot to us, and we hope to have you involved in our community in the future.
              </div>
            </div>
          )}
          {isMintActive && address && nftData && nftData?.rank !== NFT_RANK.UNRANKED && (
            <>
              <div className="border border-pGreen-400 border-dashed rounded p-4 text-sm text-pNeutral-800 space-y-2">
                <div>
                  Congratulations! You are eligible to mint a{" "}
                  <span className="text-base font-medium text-pGreen-500">{rankData?.name} Wisp NFT</span> from
                  the&nbsp;
                  <span className="text-base font-medium text-pGreen-400">Wisp of the Forest</span> Collection.
                </div>
                <div>Thank you for your continuous support for WispSwap since the early days.</div>
                <div className="italic">
                  To complete the minting process, please click on the "Mint NFT" button below. Happy minting! 🌱💫
                </div>
              </div>
              {isLimitExceeded && (
                <div className="bg-pRed-500 rounded bg-opacity-20 text-red-600 p-4 mt-4 text-sm">
                  Unfortunately, it appears that the quantity of NFTs you are trying to mint has exceeded the maximum
                  limit allowed.
                </div>
              )}
              {!nftData?.mintable && (
                <div className="mt-4 text-pRed-500 font-semibold text-center">You've minted your NFT.</div>
              )}
              <Button
                className="px-6 py-3 w-full text-xl font-Poppins whitespace-pre-wrap mt-4"
                disabled={Boolean(
                  isNftDataFetching ||
                    isRegistryFetching ||
                    isBuildingTx ||
                    !nftData?.mintable ||
                    isLimitExceeded ||
                    !isMintActive,
                )}
                isLoading={isBuildingTx}
                onClick={handleMintNft}
              >
                {isBuildingTx ? "Processing" : "Mint NFT"}
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
