import { ReactElement, useMemo, useState } from "react";
import CoinAPI from "api/coin";
import clsx from "clsx";
import { AssetLogo } from "components/atoms/AssetLogo";
import { SearchIcon } from "components/atoms/icons/SearchIcon";
import { Modal } from "components/atoms/Modal";
import { useWispSettings } from "contexts/WispSettingsContext";
import useDebounce from "hooks/useDebounce";
import { useQuery } from "react-query";
import { Amount } from "sdk/entities/amount";
import { Coin } from "sdk/entities/coin";
import { SUI } from "utils/coins";

type Props = {
  isOpen: boolean;
  assets: Amount[];
  selectedAsset: Coin | undefined;
  highlightAsset: Coin | undefined;
  isFaucet?: boolean;
  onClose: () => void;
  onSelect: (_: Coin) => void;
};

function AssetItem({
  asset,
  selected,
  highlight,
  onSelect,
}: {
  asset: Amount;
  selected: boolean;
  highlight: boolean;
  onSelect: (_: Coin) => void;
}): ReactElement {
  return (
    <div
      className={clsx(
        "p-2 flex items-center justify-between space-x-6 rounded-lg",
        selected || highlight ? "bg-pGreen-500 bg-opacity-10" : "hover:bg-pGreen-500 hover:bg-opacity-10",
        selected ? "" : "cursor-pointer",
      )}
      onClick={!selected ? (): void => onSelect(asset.coin) : undefined}
    >
      <div className="flex items-center space-x-4">
        <AssetLogo asset={asset.coin} />
        <div className="text-pNeutral-800 font-medium">{asset.coin.name}</div>
      </div>
      <div>{asset.toExact()}</div>
    </div>
  );
}

export function SelectAssetModal({
  assets,
  selectedAsset,
  highlightAsset,
  isOpen,
  isFaucet = false,
  onSelect,
  onClose,
}: Props): ReactElement {
  const { settings } = useWispSettings();
  const [searchTerm, setSearchTerm] = useState("");

  const debounceSearch = useDebounce(searchTerm, 300);

  const { data: allCoins } = useQuery<Coin[]>(
    ["get-all-coins", settings.networkEnv, debounceSearch],
    async () => {
      const resp = await CoinAPI.getCoins(settings.networkEnv, {
        searchTerm: debounceSearch,
        page: 1,
        pageSize: 1000,
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return (resp?.data?.data ?? []).map((piece: any) => {
        return new Coin(piece.package_addr, piece.module, piece.type, piece.decimal ?? 0, piece.treasury_addr, {
          imageUrl: piece.icon_url,
          ticker: piece.symbol,
          projectName: piece.symbol,
          description: piece.description,
        });
      });
    },
    {
      enabled: !isFaucet,
    },
  );

  const coinBalances = useMemo(() => {
    if (isFaucet) {
      return assets;
    }
    const _balances = (allCoins ?? []).map((c) => {
      const _amt = assets.find((as) => as.coin.equals(c));
      return _amt ? _amt : Amount.fromRawAmount(c, 0);
    });
    if (!debounceSearch) {
      for (const a of assets) {
        if (!_balances.some((b) => b.coin.equals(a.coin))) {
          _balances.push(a);
        }
      }
    }
    const sorted = _balances.sort((a, b) => {
      if (a.quotient > b.quotient) {
        return -1;
      }
      if (a.quotient < b.quotient) {
        return 1;
      }
      return 0;
    });
    const suiAmt = sorted.find((s) => s.coin.equals(SUI));
    if (!suiAmt) {
      return sorted;
    }
    const _filtered = sorted.filter((s) => !s.coin.equals(SUI));
    return [suiAmt, ..._filtered];
  }, [assets, allCoins, isFaucet, debounceSearch]);

  return (
    <Modal isOpen={isOpen} title="Select token" onClose={onClose}>
      <div className="space-y-6">
        {!isFaucet && (
          <div className="py-2 px-4 border border-white border-opacity-10 rounded-lg flex items-center space-x-4 text-sm font-medium">
            <SearchIcon className="w-5 h-5 shrink-0" />
            <input
              className="flex-1 bg-transparent"
              placeholder="Search token..."
              type="text"
              value={searchTerm}
              onChange={(e): void => setSearchTerm(e.target.value)}
            />
          </div>
        )}
        <div className="space-y-0.5">
          {coinBalances.map((asset) => (
            <AssetItem
              asset={asset}
              highlight={!!highlightAsset && asset.coin.equals(highlightAsset)}
              key={asset.coin.type}
              selected={!!selectedAsset && asset.coin.equals(selectedAsset)}
              onSelect={onSelect}
            />
          ))}
        </div>
      </div>
    </Modal>
  );
}
