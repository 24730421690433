import { ReactElement, useState } from "react";
import { Popover } from "@headlessui/react";
import { useWallet } from "@suiet/wallet-kit";
import clsx from "clsx";
import { AssetLogo } from "components/atoms/AssetLogo";
import { Button } from "components/atoms/Button";
import { Divider } from "components/atoms/Divider";
import { LogoutIcon } from "components/atoms/icons/LogoutIcon";
import { TriangleDownIcon } from "components/atoms/icons/TriangleDownIcon";
import { LoadingRing } from "components/atoms/LoadingRing";
import { MultipleAssetLogo } from "components/atoms/MultipleAssetLogo";
import { Spinner } from "components/atoms/Spinner";
import { useWalletHelper } from "contexts/WalletHelperContext";
import { useWispSettings } from "contexts/WispSettingsContext";
import { intlFormatNumber } from "sdk/utils/formatNumber";
import { trimZeroes } from "sdk/utils/trimZeros";
import { shortenAddress } from "utils";
import { SUI } from "utils/coins";
import { NetworkEnv } from "utils/constant";

import { RequestFaucetModal } from "./RequestFaucetModal";
import { SelectCustomRpc } from "./SelectCustomRpc";

type Props = {
  cls?: string;
};

export function Wallet({ cls }: Props): ReactElement {
  const suietWallet = useWallet();
  const { fungibleBalances, lpBalances, isBalanceLoading, setOpenSelectWallet } = useWalletHelper();
  const [isOpenRpc, setIsOpenRpc] = useState(false);
  const [openFaucetModal, setOpenFaucetModal] = useState<boolean>(false);
  const { settings } = useWispSettings();

  const connected = suietWallet.connected;
  const connecting = suietWallet.connecting;

  const suiBalance = fungibleBalances?.find((fb) => fb.coin.equals(SUI));

  if (!connected && !connecting) {
    return (
      <Button className={clsx("px-6 py-2 font-medium", cls)} onClick={(): void => setOpenSelectWallet(true)}>
        Connect Wallet
      </Button>
    );
  }

  return (
    <div>
      <Popover as="div" className="relative z-20">
        {({ open }): ReactElement => (
          <>
            <Popover.Button className="w-full min-w-[200px]">
              <div className="flex items-center justify-center space-x-2 pl-6 pr-3 py-1 rounded-full bg-btn text-white font-semibold">
                {connecting ? (
                  <div className="flex-1 flex items-center justify-center">
                    <LoadingRing size={16} />
                  </div>
                ) : (
                  <>
                    {!isBalanceLoading ? (
                      <span className="font-DmSans text-base">
                        {suiBalance ? trimZeroes(suiBalance.toExact(), { decimalPlaces: 4 }) : "0"}
                      </span>
                    ) : (
                      <Spinner className="w--4 h-4" />
                    )}
                    <span className="w-[2px] h-5 bg-white"></span>
                    <span className="font-Poppins text-base">{shortenAddress(suietWallet.address)}</span>
                  </>
                )}
                <TriangleDownIcon
                  className={clsx("w-8 h-8 shrink-0 transition-all duration-200", open ? "rotate-180" : "")}
                />
              </div>
            </Popover.Button>
            <Popover.Panel className="absolute right-0 mt-1 w-full origin-top-right min-w-[300px] rounded-lg p-4 shadow-md ring-1 ring-white ring-opacity-20 bg-dark-600 z-20">
              <div className="space-y-4">
                <div className="space-y-3 max-h-80 overflow-y-auto">
                  {fungibleBalances &&
                    fungibleBalances.map((b) => {
                      const value = b.toExactNumber();
                      return (
                        <div
                          className="flex items-center justify-between space-x-4 p-2 text-pNeutral-800"
                          key={b.coin.type}
                        >
                          <div className="flex items-center space-x-4">
                            <AssetLogo asset={b.coin} />
                            <span className="font-medium">{b.coin.name}</span>
                          </div>
                          <div>{value < 0.01 ? "<0.01" : intlFormatNumber(value)}</div>
                        </div>
                      );
                    })}
                  {lpBalances &&
                    lpBalances.map((b) => {
                      const value = b.toExactNumber();
                      return (
                        <div
                          className="flex items-center justify-between space-x-4 p-2 text-pNeutral-800"
                          key={b.coin.type}
                        >
                          <div className="flex items-center space-x-4">
                            <MultipleAssetLogo assets={[b.coin.coinA, b.coin.coinB]} />
                            <span className="font-medium">{`${b.coin.coinA?.name}-${b.coin.coinB?.name} LP`}</span>
                          </div>
                          <div>{value < 0.01 ? "<0.01" : intlFormatNumber(value)}</div>
                        </div>
                      );
                    })}
                </div>
                <Divider />
                <div className="space-y-3">
                  {settings.networkEnv !== NetworkEnv.MAINNET && (
                    <button
                      className="bg-gray-700 rounded-md py-2 px-2 w-full text-sm"
                      onClick={(): void => setOpenFaucetModal(true)}
                    >
                      Faucet
                    </button>
                  )}
                  <button
                    className="bg-gray-700 rounded-md py-2 px-2 w-full text-sm"
                    onClick={(): void => setIsOpenRpc(true)}
                  >
                    Custom RPC
                  </button>
                </div>
                <Divider />
                <a
                  className="px-2 cursor-pointer space-x-4 flex items-center justify-start text-red-500 w-fit"
                  onClick={suietWallet.disconnect}
                >
                  <LogoutIcon className="w-6 h-6" />
                  <span>Disconnect</span>
                </a>
              </div>
            </Popover.Panel>
          </>
        )}
      </Popover>
      <SelectCustomRpc isOpen={isOpenRpc} onClose={(): void => setIsOpenRpc(false)} />
      <RequestFaucetModal isOpen={openFaucetModal} onClose={(): void => setOpenFaucetModal(false)} />
    </div>
  );
}
