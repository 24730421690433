import { FC } from "react";
import { FlameIcon } from "components/atoms/icons/FlameIcon";

export const WISP_CONFIG = {
  dex: {
    swapPackage: "0x6c4a21e3e7e6b6d51c4604021633e1d97e24e37a696f8c082cd48f37503e602a",
    swapModulePool: "pool",
    swapModuleRouter: "router",
    swapController: "0xf8fdf7c74d6f084e45092423b937679c2b702b3ce561362dae3494c2826e5862",
    swapSetting: "0x33dad3c4a8ec662326d69d94151ab157e26e621830cce125e97965a0111c37c4",
    functions: {
      createPool: "create_pool_",
      addLiquidity: "add_liquidity_",
      removeLiquidity: "remove_liquidity_",
      zapIn: "zap_in_",
      swapExactInput: "swap_exact_input_",
      swapExactOutput: "swap_exact_output_",
      swapExactInputDoubleHop: "swap_exact_input_doublehop_",
      swapExactOutputDoubleHop: "swap_exact_output_doublehop_",
      setFeeTo: "set_fee_to_",
      mint: "mint_for_testing",
    },
  },
  prediction: {
    // owner: "0x3f3b11a18ffe59368cb935771df277ac531bf60b9a0a201c78e9d1aabe7bc214",
    packageObject: "0xa0d6f9d848a04bdba7f308aaedcfa92bfb3432fa2f81e1166559d83c74bc17f6",
    moduleName: "prediction",
    controller: "0x255f41757ff5d5e25f0bc4cfd9d1b838d4b15ab52ee9ca16a0c0b29c41d9cfaa",
    market: "0x8c2164a79c7d959940f852da91b6d74729c9e05b21bd2c665a2ba084a5a35e8e",
    coin: "0x0000000000000000000000000000000000000000000000000000000000000002::sui::SUI",
    functions: {
      betBull: "bet_bull_",
      betBear: "bet_bear_",
      unBet: "un_bet_",
      claim: "claim_",
    },
  },
  nftMinting: {
    registry: "0x87524b1eb6945d474f311fbfa053b2195431e32b7495f2d6f738aac26981968b",
    package: "0x9b455e76a7a27eb3b7e046b26f0f42b57901110cff7cc0c29c0be7d7c2b3d514",
    module: "whitelist",
  },
};

export enum PATH {
  TRADE = "#",
  POOL = "#",
  FARM = "#",
  PREDICTION = "#",
  WISPPOINT = "/",
  IDO = "#",
  ADD_LIQUIDITY = "#",
  REMOVE_LIQUIDITY = "#",
  BRIDGE = "#",
  WORMHOLE = "/bridge/wormhole",
}

export const LANDING_PAGE = "https://wispswap.io";

export type NavigationItem = {
  title: string;
  path?: PATH;
  children?: NavigationItem[];
  icon?: FC;
};

export const NAVIGATION_ITEMS: NavigationItem[] = [
  { title: "Trade", path: PATH.TRADE },
  { title: "Pools", path: PATH.POOL },
  { title: "Farm", path: PATH.FARM },
  { title: "Prediction", path: PATH.PREDICTION },
  {
    title: "Bridge",
    path: PATH.WORMHOLE,
    // children: [
    //   {
    //     title: "Celer",
    //     path: PATH.BRIDGE,
    //   },
    //   {
    //     title: "Wormhole",
    //     path: PATH.WORMHOLE,
    //   },
    // ],
  },
  { title: "IDO", path: PATH.IDO, icon: FlameIcon },
];

export enum LocalStorageKey {
  SETTINGS = "wisp-settings",
}

export enum NetworkEnv {
  MAINNET = "sui:mainnet",
  TESTNET = "sui:testnet",
  DEVNET = "sui:devnet",
}

export const networkInfo: Record<string, string> = {
  [NetworkEnv.DEVNET]: "Sui Devnet",
  [NetworkEnv.TESTNET]: "Sui Testnet",
  // [NetworkEnv.MAINNET]: "Sui Mainnet",
};

export const OPEN_SUI_WALLET = "OpenSui Wallet";

export const DEFAULT_RPC_TESTNET = "https://fullnode.testnet.sui.io:443/";
export const DEFAULT_RPC_MAINNET = "https://fullnode.mainnet.sui.io:443/";

const CUSTOM_RPCS_TESTNET = [
  {
    name: "Public Sui Testnet",
    rpc: DEFAULT_RPC_TESTNET,
  },
  {
    name: "Shinami",
    rpc: "https://node.shinami.com/api/v1/sui_testnet_d26f8937d384856b1a62778a01faf35a",
  },
  {
    name: "BlockVision",
    rpc: "https://sui-testnet.blockvision.org/v1/2PVVjrTJIfPuG6J0YNMNutrvAcW",
  },
];

const CUSTOM_RPCS_MAINNET = [
  {
    name: "Public Sui Mainnet",
    rpc: DEFAULT_RPC_MAINNET,
  },
  {
    name: "Block Vision",
    rpc: "https://sui-mainnet.blockvision.org/v1/2PVVihoF9cMzI4OkmB2aRdnsv6r",
  },
];

export function getCustomRpcs(networkEnv: NetworkEnv): { name: string; rpc: string }[] {
  if (networkEnv === NetworkEnv.MAINNET) {
    return CUSTOM_RPCS_MAINNET;
  }
  return CUSTOM_RPCS_TESTNET;
}
